<template>
  <!-- 人员管理页面 -->
  <div class="padding24" id="person_manage">
    <div v-show="$route.meta.isShow">
      <!-- 顶部 -->
      <div class="top">
        <div class="top_left">
          <a-button icon="plus" type="primary" @click="addPerson" v-show="isShowAdd">添加人员</a-button>
        </div>
        <div class="top_rigiht">
          <a-select v-model="defaRoleName" allowClear style="width: 120px" placeholder="请选择角色">
            <a-select-option v-for="item in roleNameArr" :key="item.roleNo" >{{ item.roleName }}</a-select-option>
          </a-select>
          <a-input ref="userNameInput" v-model="searchVal" placeholder="请输入手机号码" style="width: 200px" @pressEnter="searchClick">
            <a-icon slot="prefix" type="search" />
          </a-input>
          <a-button icon="search" type="primary" @click="searchClick">搜索</a-button>
        </div>
      </div>

      <!-- 表格 -->
      <a-table :columns="columns" :data-source="tableData" :row-key="record => record.adminNo" :pagination="false" :loading="tableLoading" bordered>
        <img v-viewer class="avatar" slot="headUrl" slot-scope="headUrl, record" :src="record.headUrl"/>
        <span slot="status" slot-scope="status, record">
          <a-badge v-if="record.status == 1" status="success" text="正常"/>
          <a-badge v-if="record.status == 0" status="error" text="禁用"/>
        </span>
        <span slot="operation" slot-scope="operation, record">
          <a v-show="isShowResetPass" class="margin_right20" @click="resetPwdClick(record)">重置密码</a>
          <a v-show="record.status == 1 && isShowDisable" class="margin_right20" @click="isDisableClick(record)">停用</a>
          <a v-show="record.status == 0 && isShowEnable" class="margin_right20" @click="isEnableClick(record)">启用</a>
          <a v-show="isShowEdit" class="margin_right20" @click="editCLick(record)" >编辑</a>
          <a v-show="isShowDelete" class="margin_right20" @click="delClick(record)" >删除</a>
        </span>
      </a-table>

      <!-- 重置密码的弹框 -->
      <a-modal v-model="modalVisible" :footer="null">
        <div class="reset_pwd">
          <a-icon type="exclamation-circle" />
          <span>重置密码</span>
        </div>
        <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules" :labelCol="{ span: 5 }" :wrapperCol="{ span: 16 }" labelAlign="left">
          <a-form-model-item ref="newPassword" label="输入新密码" prop="newPassword">
            <a-input-password v-model="ruleForm.newPassword" type="password" />
          </a-form-model-item>
          <a-form-model-item ref="confirmPwd" label="确认新密码" prop="confirmPwd">
            <a-input-password v-model="ruleForm.confirmPwd" type="password" />
          </a-form-model-item>
          <a-button type="primary" @click="submitForm(ruleForm)" :loading="resetPwdLoading" style="margin-left: 40%">确定</a-button>
        </a-form-model>
      </a-modal>

      <!-- 分页功能 -->
      <div class="page">
         <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="tableData.length > 0"/>
      </div>

      <!-- 返回顶部 -->
      <a-back-top :target="targetFn" :visibilityHeight="100" />
    </div>
    <router-view />
  </div>
</template>

<script>
import { JSEncrypt } from "jsencrypt";
import {
	userListApi,
	RoleoptionList,
	userDeleteApi,
	userDisableApi,
	userEnableApi,
  EditPassword,
} from "@/request/api/rolePower";
import { Modal, message } from "ant-design-vue";
import MyPagination from "@/components/pagination/MyPagination";
import { codeFn } from "@/utils/tools";
export default {
  components: { MyPagination },
  created() {
    this.userListFn();
    this.roleListFn();
    if (codeFn("/admin/sys/user/add")) this.isShowAdd = true;
    if (codeFn("/admin/sys/user/reset/password/{id}")) {this.isShowResetPass = true;}
    if (codeFn("/admin/sys/user/disable/{id}")) this.isShowDisable = true;
    if (codeFn("/admin/sys/user/enable/{id}")) this.isShowEnable = true;
    if (codeFn("/admin/sys/user/{id}")) this.isShowEdit = true;
    if (codeFn("/admin/sys/user/delete/{id}")) this.isShowDelete = true;
  },
  data() {
    return {
      pageNo: 1, // 当前页
      pageSize: 20, // 每页条数
      searchVal: "", // 搜索框的值
      tableLoading: true, // 表格loading图
      modalVisible: false, // 重置密码模态框是否显示
      resetPwdLoading: false, // 重置密码弹框的的确定按钮的loading图
      resPwdId: "", // 点击重置密码获取的id
      defaRoleName: undefined, // 选择框默认值
      roleIdArr: [], // 下拉框角色管理ID的数组
      roleNameArr: [], // 选择框
      ruleForm: { newPassword: "", confirmPwd: "" },
      rules: {
        newPassword: [{ validator: this.validatePwd, trigger: "blur" }],
        confirmPwd: [{ validator: this.validateConfirmPwd, trigger: "blur" }],
      },
      columns: [
        {
          title: "编号",
          dataIndex: "adminNo",
          key: "adminNo",
          width: "18%",
        },
        {
          title: "姓名",
          dataIndex: "name",
          width: "13%",
          key: "name",
        },
        {
          title: "手机号码",
          dataIndex: "phone",
          width: "15%",
          key: "phone",
        },
        {
          title: "角色",
          dataIndex: "roleName",
          key: "roleName",
          width: "12%",
        },
        {
          title: "状态",
          dataIndex: "status",
          width: "8%",
          key: "status",
          scopedSlots: { customRender: "status" },
        },
        {
          title: "创建时间",
          dataIndex: "createTime",
          width: "18%",
          sorter: true,
          key: "createTime",
          sorter: (a, b) => {
            let aTime = new Date(a.createTime).getTime();
            let bTime = new Date(b.createTime).getTime();
            return aTime - bTime;
          },
        },
        {
          title: "操作",
          key: "operation",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      tableData: [],
      key: `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDR9fKOSRTLAtABTQAuSljGQXkU0ZR3kpCsnxoiOfxEMCsuhJIKFKpxxDa0exnucGY5lS1g0QckvRODnxniiazNzOqNUv2z4G3VKpkXDUET2R17kjPchw6EaZZ4RZGzqhuRHj4vx/5Pxm3ET+j8gN3brlgeXjUzdM5gHTBw9uTXoQIDAQAB`,
      count: 0, // 列表的总条数
      isShowAdd: false, // 是否显示新增的按钮
      isShowResetPass: false, // 是否显示重置密码的按钮
      isShowDisable: false, // 是否显示停用的按钮
      isShowEnable: false, // 是否显示启用的按钮
      isShowEdit: false, // 是否显示编辑的按钮
      isShowDelete: false, // 是否显示删除的按钮
    };
  },
  watch: {
    $route(to) {
      if (to.path === "/rolePower/personManage") {
        this.userListFn();
      }
    },
    searchVal(newVal) {
      if (newVal === "") {
        this.userListFn();
      }
    },
  },
  methods: {
    // 回到顶部的函数
    targetFn() {
      return document.querySelector("#person_manage");
    },
    // 添加人员
    addPerson() {
      this.$router.push("/rolePower/roleManage/addPerson/88");
    },
    // 下拉选择框的回调
    selectChange(value) {
      this.roleIdArr = [];
      if (value !== "") {
        this.roleIdArr.push(value);
      }
      this.userListFn();
    },
    // 搜索按钮 , 回车搜索
    searchClick () {
      this.tableData = []
      this.pageNo = 1;
      this.userListFn();
    },
    // 重置密码按钮
    resetPwdClick(data) {
      if (this.ruleForm.newPassword != "" || this.ruleForm.confirmPwd != "") {
        this.$refs["ruleForm"].resetFields();
      }
      this.modalVisible = true;
      this.resPwdId = data.adminNo;
    },
    // 重置密码的确定按钮
    submitForm(ruleForm) {
      this.resetPwdLoading = true;
      setTimeout(() => {
        this.resetPwdLoading = false;
      }, 3000);
      this.$refs["ruleForm"].validate((result) => {
        if (result) {
          EditPassword({
            adminNo: this.resPwdId,
            password: this.ruleForm.newPassword
          }).then(({ code }) => {
            if (code == 200) {
              message.success("修改密码成功", 3);
              this.resetPwdLoading = false;
              this.modalVisible = false;
            }
          });
        }
      });
    },
    // 启用的按钮
    isEnableClick(record) {
      userEnableApi({ adminNo: record.adminNo }).then(({ code, data }) => {
        if (code == 200) {
			message.success("启用成功", 1);
		  	this.userListFn()
        }
      });
    },
    // 停用的按钮
	isDisableClick(record) {
		let self = this
		Modal.confirm({
			title: "提示",
			content: "停用后该账号无法登陆后台, 是否确认停用",
			okText: "确认",
			okType: "danger",
			cancelText: "取消",
			onOk() {
				userDisableApi({ adminNo: record.adminNo }).then(({ code }) => {
					if (code == 200) {
						message.error("停用成功", 1);
						self.userListFn()
					}
				});
			},
      });
    },
    // 编辑的按钮
    editCLick(record) {
      this.$router.push(`/rolePower/roleManage/addPerson/${record.adminNo}`);
    },
    // 删除的按钮
    delClick(record) {
      let that = this;
      Modal.confirm({
        title: "提示",
        content: "删除后相关数据无法恢复, 是否确认删除",
        okText: "确认",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          userDeleteApi({ id: record.id }).then(({ code }) => {
            if (code == 200) {
              message.success("删除成功", 3);
              let tableData = that.tableData;
              that.tableData = tableData.filter(
                (item) => item.id !== record.id
              );
            }
          });
        },
      });
    },
    // 分页切换页数
    showSizeChangeFn(current, pageSize) {
      this.pageNo = current;
      this.pageSize = pageSize;
      this.userListFn();
    },
    // 密码校验规则
    validatePwd(rule, value, callback) {
      let reg =
        /(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^[^\s\u4e00-\u9fa5]{8,16}$/;
      if (value === "") {
        callback("输入的内容不能为空");
      } else if (!reg.test(value)) {
        callback(
          "请输入8-16位密码,数字,字母,字符至少包含两种,不能包含中文和空格"
        );
      } else {
        callback();
      }
    },
    // 确认密码校验规则
    validateConfirmPwd(rule, value, callback) {
      if (value == "") {
        callback("输入的内容不能为空");
      } else if (value != this.ruleForm.newPassword) {
        callback("和上面密码不一致, 请重新输入");
      } else {
        callback();
      }
    },
    // 封装请求用户列表函数
	userListFn() {
		userListApi({
			page: this.pageNo,
			roleNo: this.defaRoleName,
			phone:this.searchVal,
		}).then(({ code, data }) => {
			if (code == 200) {
				this.count = data.count;
				this.tableLoading = false;
				this.tableData = data.list;
			}
		});
    },
    // 封装请求角色列表函数
    roleListFn() {
		RoleoptionList({isAgent:-1}).then(({ code, data }) => {
			if (code == 200) {
				this.roleNameArr = data
			}
		});
    },
    // 密码加密
    encryption(msg, key) {
      let encryptor = new JSEncrypt(); // 创建加密对象实例
      //之前ssl生成的公钥，复制的时候要小心不要有空格
      encryptor.setPublicKey(key); //设置公钥
      let rsaPassWord = encryptor.encrypt(msg); // 对内容进行加密
      return rsaPassWord;
    },
  },
};
</script>

<style lang="less" scoped>
#person_manage {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  background-color: #fff;
}
.top {
  display: flex;
  justify-content: space-between;
  .top_rigiht {
    .ant-btn {
      margin-left: 10px;
      // background-color: #f5f5f5;
    }
  }
  .ant-select {
    margin-right: 10px;
  }
}
.avatar {
  width: 50px;
  height: 50px;
  // border-radius: 50%;
  object-fit: contain;
}
.whatColor {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: green;
}
.red {
  background-color: red;
}
// 重置密码
.reset_pwd {
  text-align: center;
  margin: 20px 0;
  font-size: 20px;
  font-weight: 600;
  .anticon {
    font-size: 40px;
    margin-right: 20px;
    color: #409eff;
  }
}
.text {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
}
.del_btn {
  margin-top: 60px;
  display: flex;
  justify-content: space-around;
  .ant-btn {
    width: 100px;
  }
}
/deep/ .ant-table-wrapper {
  margin-top: 40px;
}
// 表头字体变粗
/deep/.ant-table-thead > tr > th {
  font-weight: 600;
}
.page{
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    padding-right: 10px;
}
</style>
